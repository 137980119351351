import React from "react"
import HeadingL from "../../components/shared/HeadingL"
import Layout from "../../components/noone/Layout"
import ProgramActivityExample from "../../components/noone/ProgramActivityExample"
import ProgramSchedule from "../../components/noone/ProgramSchedule"
import SEO from "../../components/Seo"
import { PAGE } from "../../constants"

const program = () => {
  return (
    <Layout>
      <SEO title={PAGE.title.program} siteTitle="noone" />
      <HeadingL title={PAGE.title.program} target="noone" />
      <ProgramSchedule />
      <ProgramActivityExample />
    </Layout>
  )
}

export default program
