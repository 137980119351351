import React from "react"
import HeadingM from "../../components/shared/HeadingM"
import Schedule from "../../components/shared/program/Schedule"

const program = () => {
  const items = [
    {
      day: "火",
      contents: "創作活動、余暇の提供、イベント等を実施しております。",
    },
    {
      day: "水・木",
      contents:
        "①１１：００～１２：００ 外出、学習、リズム、体幹トレーニング、余暇\n②１６：００～１６：３０・エクササイズ・リズム",
    },
    {
      day: "金",
      contents:
        "ヨガ\n1回10分～40分。子ども達の様子やヨガの内容に合わせて、個別や少人数または全体で実施しております。",
    },
    { day: "土・祝日", contents: "外出活動等\n１４：００～１５：３０" },
  ]
  return (
    <div>
      <HeadingM title="スケジュール" target="noone" />
      <Schedule items={items} target="noone" />
    </div>
  )
}

export default program
